import React from 'react'
import video from '../assets/JoCo_vid2_safety-and-mobility_30SEC_HQ.mp4'

export default function VideoSafety() {
    return (
        <div className='p-5'>
            <video controls="controls" name="Johnson County" id="videoDiv" style={{ width: "100%" }}>
                <source src={video} />
            </video>
        </div>
    )
}
