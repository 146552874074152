import { Link } from "react-router-dom"

export default function Report(props) {
    const year = props.event.Year
    const title = props.event.title
    const link = props.event.Link

    /*
    FOR REPORTS.JSON:
        {
            "Year":"2017",
            "title":"",
            "Link":"/assets/reports/doc.pdf",
            "id":1
        }
    */

    return (
        <tr>
            <td>
                <Link style={{ textDecoration: "none", color: "black" }} to={link} target="_blank">{year} ◦ {title}</Link>
            </td>
        </tr>
    )
}