import React from 'react'

export default function VideoJCB() {
    return (
        <div className=' h-100'>
            <iframe
            src="https://clients.freese.com/johnsoncountybond.com/JCBVideo.mp4"
            style={{ width: "100%", height: "1000px" }}>
            </iframe>
        </div>
    )
}
