import React, { createRef, useEffect, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import Projects from '../components/Projects.json'
import Spinner from '../components/Spinner'

export default function ViewProjects(props) {
    /*
    {
        "id": 17,
        "title": "",
        "projNum": "",
        "projLen": "",
        "projCost": "",
        "existingCon": "",
        "projDesc": "",
        "map": "/assets/projects/"
    }    
    */

    const { id } = useParams();
    const [projNum, setProjNum] = useState('')
    const [title, setTitle] = useState('')
    const [projLen, setProjLen] = useState('')
    const [projCost, setProjCost] = useState('')
    const [existingCon, setExistingCon] = useState('')
    const [projDesc, setProjDesc] = useState('')
    const [map, setMap] = useState('')

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const ref = createRef();
    const { pathname } = useLocation();
    const path = pathname.split("/")

    useEffect(() => {
        var data = null;

        for (let i = 0; i < Projects.length; i++) {
            if (id == Projects[i].id) {
                data = Projects[i]
            }
        }
        if (data == null) {
            setError(true)
        }


        setProjNum(data.projNum)
        setTitle(data.title)
        setExistingCon(data.existingCon)
        setProjLen(data.projLen)
        setProjCost(data.projCost)
        setProjDesc(data.projDesc)
        setMap(data.map)

        setLoading(false)

    }, [id]);


    if (path[1].toLowerCase() === 'viewprojects') {
        if (isNaN(path[2])) return (window.location.href = "/NotFound")
        const projId = parseInt(path[2])
        if (projId < 1) return (window.location.href = "/NotFound")
    }

    if (loading) {
        return (<main className='flex-shrink-0 modular min-vh-100'><Spinner padding={5} /></main>)
    }

    if (error) {
        return (<main className='flex-shrink-0 modular min-vh-100'><div>Page Not Found</div></main>)
    }

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div className="container py-4 text-center">
                    <div ref={ref} id='project' className="row my-4 text-start ">
                        <h3 className='text-center'>{title}</h3>
                        <div className='col-lg-6'>
                            <img src={map} className='w-100' />
                        </div>
                        <div className='col-lg-6'>
                            <div className='row my-2'>
                                <div className='col-4 text-end'>
                                    <h6>Highway / Limits:</h6>
                                </div>
                                <div className='col-8'>
                                    {title}
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-4 text-end'>
                                    <h6>Project Number:</h6>
                                </div>
                                <div className='col-8'>
                                    {projNum}
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-4 text-end'>
                                    <h6>Project Length:</h6>
                                </div>
                                <div className='col-8'>
                                    {projLen}
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-4 text-end'>
                                    <h6>Program Cost (Est.):</h6>
                                </div>
                                <div className='col-8'>
                                    {projCost}
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <h6>Existing Conditions:</h6>
                                </div>
                                <div className='col-12'>
                                    {existingCon}
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <h6>Proposed Project Description:</h6>
                                </div>
                                <div className='col-12'>
                                    {projDesc}
                                </div>
                            </div>
                        </div>


                    </div>
                    <Link to="/projects" style={{ textDecoration: "none" }}>
                        <div className='btn btn-outline-secondary btn-sm'>Back to Projects</div>
                    </Link>
                </div>

            </main>
        </div>
    )
}