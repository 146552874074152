import React, { createRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ProjectsData from '../components/Projects.json'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link, useNavigate } from 'react-router-dom'
import img from '../assets/Johnson Co - Final Bond Program Map - 8.4.24.png'

export default function Projects() {
    const navigate = useNavigate();

    const map = {}

    const titleBodyTemplate = (item) => {
        return <Link to={"/viewprojects/" + item.id}>{item.title}</Link>
    };

    const projNumBodyTemplate = (item) => {
        return <a onClick={() => navigate("/viewprojects/" + item.id)}>{item.projNum}</a>
    }

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div className="container py-4">
                    <div className="row mb-3">
                        <h1 className='mb-3'>Projects Map</h1>
                        <div className='col-12'>
                            <iframe title="Precinct Map" style={{ width: "100%", maxHeight: "100%", overflow: "hidden", overflowX: "hidden", overflowY: "hidden" }} height="600" width="1200" src="https://www.arcgis.com/apps/dashboards/bb82d20c212449df9942768e39ddbd7c"></iframe>
                        </div>
                    </div>
                    <div className="row">
                        <h1>Projects</h1>
                        <DataTable stripedRows value={ProjectsData}>
                            <Column sortable field="projNum" body={projNumBodyTemplate} header="Project"></Column>
                            <Column sortable field="title" body={titleBodyTemplate} header="Name"></Column>
                        </DataTable>
                    </div>
                </div>
            </main>
        </div>
    )
}