import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFolderOpen, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'
import { faCalendar as farCalendar, faFolderOpen as farFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { Skeleton } from 'primereact/skeleton';
import img from '../assets/Johnson Courthouse Photo.jpg'
import logo from '../assets/Johnson County Transportation Bond logo transparent.png'
import video from '../assets/Johnson County Bond Announcement Video.MOV'

export default function Home() {

    const calendaricn = <FontAwesomeIcon icon={farCalendar} fontSize={"85px"} color='#124c7c' />
    const foldericn = <FontAwesomeIcon icon={farFolderOpen} fontSize={"85px"} color='#124c7c' />
    const charticn = <FontAwesomeIcon icon={faPersonChalkboard} fontSize={"85px"} color='#124c7c' />

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>

                <img className="d-block w-100" src={img} style={{ objectFit: "cover", height: "20em" }} alt="Home" />

                <div className="container py-4">
                    <div className='row mx-5 mt-4'>
                        <div className='col-sm-3 col-12 text-center'>
                            <img src={logo} height={"300px"} alt="Johnson County Transportation Program" />
                            <div className='pt-3'>
                                <a href="https://www.x.com/@JohnsonCoBond" target='_blank' style={{ textDecoration: "none" }}><h5 className='pb-2'><i class="fa-brands fa-square-x-twitter"></i> @JohnsonCoBond</h5></a>
                                <a href="https://www.instagram.com/JohnsonCountyBond" target='_blank' style={{ textDecoration: "none" }}><h5 className='pb-2'><i class="fa-brands fa-instagram"></i> @JohnsonCountyBond</h5></a>
                                <a href="https://www.facebook.com/people/Johnson-County-Transportation-Bond/61566498354803/" target='_blank' style={{ textDecoration: "none" }}><h5><i class="fa-brands fa-square-facebook"></i> Johnson County Transportation Bond</h5></a>
                            </div>
                        </div>
                        <div className="col-sm-9 col-12">
                            <h1 className='mb-3'>Johnson County Bond Program</h1>
                            <video controls="controls" name="Johnson County" id="videoDiv" style={{ width: "100%" }}>
                                <source src={video} />
                            </video>
                            <p>
                                Johnson County continues to experience population growth, with approximately 90% of its 180,000 residents relying on the roadway system for their commute. To address the increased demand, this bond program aims to improve safety and mobility for county residents. Johnson County is proposing a $60 million transportation bond for voter consideration. This program will complement the ongoing efforts of the Texas Department of Transportation (TxDOT) and the North Central Texas Council of Governments (NCTCOG). Additionally, the County Bond program will focus on project planning and engineering, with the goal of partnering with TxDOT and NCTCOG to secure additional state and federal funds for construction.
                                <br />
                                <br />
                                To keep you informed about these initiatives and involve you in the process, we encourage you to explore this website. By clicking on the <Link to="/projects">projects tab</Link>, you can get detailed information about the projects that will be part of the 2024 bond election. This election is set for public consideration on November 5, 2024.
                                <br />
                                <br />
                                Moreover, we invite you to participate in the upcoming City Council and Town Hall meetings to learn about the proposed projects. To find out the meeting location and date, simply click on the <Link to="/events">events tab</Link> on this website.
                            </p>

                        </div>
                    </div>

                    <div>
                        <div className='row p-4 text-center'>
                            <div className='col-lg-4'>
                                <Link to='/events' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {calendaricn}
                                    <h5 className='my-2'>Events</h5>
                                </Link>
                            </div>
                            <div className='col-lg-4'>
                                <Link to='/projects' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {foldericn}
                                    <h5 className='my-2'>Projects</h5>
                                </Link>
                            </div>
                            <div className='col-lg-4'>
                                <Link to='/presentations' style={{ textDecoration: "none", color: "#124c7c" }}>
                                    {charticn}
                                    <h5 className='my-2'>Presentations</h5>
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4'>

                    </div>
                </div>

            </main >
        </div >
    )
}