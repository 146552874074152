import React from 'react'

export default function VideoProgram() {
    return (
        <div className=' h-100'>
            <iframe
            src="https://clients.freese.com/johnsoncountybond.com/JoCo_vid3_Bond%20Program%20Hero_HQ.mp4"
            style={{ width: "100%", height: "1000px" }}>
            </iframe>
        </div>
    )
}
