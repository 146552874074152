import { React, useState, useEffect } from 'react'
import ReportList from "../components/Reports.json";
import Report from '../components/Report'
import img from '../assets/Johnson Courthouse Photo.jpg'
import { Skeleton } from 'primereact/skeleton';
import video from '../assets/Johnson County Bond Announcement Video.MOV'

export default function MonthlyReport() {

    /* 
        {
        "Year":"2024",
        "Title":"May Report",
        "Link":"/assets/Reports/Parker County Monthly - May 2024.pdf",
        "id":1
        }
    */

    const [totallistList, settotalListList] = useState([]);

    useEffect(() => {

        ReportList.sort((a, b) => {
            return (a.id) - (b.id);
        });

        var totallist = [];

        ReportList.forEach(e => {
            totallist.push(<Report event={e} key={e.id} />)
        });

        settotalListList(totallist);


    }, [])


    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>

                <img src={img} className="d-block w-100" style={{ objectFit: "cover", height: "20em" }} alt="Home" />

                <div className="container py-4">
                    <h1 className='mb-3'>Presentations</h1>
                    {(totallistList.length === 0) ?
                        <h3 className="text-center py-2" style={{ color: "grey" }}><em>No Presentations Available</em></h3>
                        :
                        <table className="table table-striped table-hover mx-auto">
                            <tbody>
                                {totallistList}
                            </tbody>
                        </table>
                    }
                <br/>
                </div>
            </main>
        </div>
    )
}