import React from 'react'
import Video from '../assets/JoCo_vid4_Early-Voting_LQ.mp4'

export default function VideoEV() {
    return (
        <div className='p-5'>
            <video controls="controls" name="Johnson County" id="videoDiv" style={{ width: "100%" }}>
                <source src={Video} />
            </video>
        </div>
    )
}
