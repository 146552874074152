import React from 'react'
import Video from '../assets/Johnson County Bond Announcement Video.MOV'

export default function VideoPage() {
    return (
        <div className='p-5'>
            <video controls="controls" name="Johnson County" id="videoDiv" style={{ width: "100%" }}>
                <source src={Video} />
            </video>
        </div>
    )
}
